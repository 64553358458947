import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import pages from './routes/pages'
import ticket from './routes/ticket'

import trader from './routes/trader'
import maintain from './routes/maintain'
import mem from './routes/mem'
import rptbill from './routes/rptbill'
import setting from './routes/setting'
import message from './routes/message'
import liveScout from './routes/liveScout'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'main' } },
    ...trader,
    ...pages,
    ...ticket,
    ...maintain,
    ...mem,
    ...rptbill,
    ...setting,
    ...message,
    ...liveScout,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
 
  // if (!canNavigate(to)) {
    // Redirect to login if not logged in
    
  if (!isLoggedIn && to.name !== 'auth-login') return next({ name: 'auth-login' })

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  // } 

  // Redirect if logged in
  if (canNavigate(to) && to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  
  if (_.name && _.name !== to.name && to.meta.type && to.meta.type === 'open') {
    const routeData = router.resolve(to)
    const params = []
    Object.keys(routeData.route.params).forEach(key => {
      params.push(`${key}=${routeData.route.params[key]}`)
    })
    window.open(`${routeData.href}${params.length > 0 ? `?${params.join('&')}` : ''}`, '_blank', `height=${window.screen.height},width=${window.screen.width / 2},alwaysRaised=yes,location=0`)
    return false
  }
  if (_.name && _.name !== to.name && to.meta.link && to.meta.link === true) {
    const routeData = router.resolve(to)
    const params = []
    Object.keys(routeData.route.params).forEach(key => {
      params.push(`${key}=${routeData.route.params[key]}`)
    })
    window.location.href = routeData.href
    return false
  }
  document.title = to.meta.title
  const iframe = document.createElement('iframe')
      iframe.src = '/favicon.ico'
      iframe.style.display = 'none'
      const fn = () => {
        setTimeout(() => {
          iframe.removeEventListener('load', fn)
          document.body.removeChild(iframe)
        }, 0)
      }
      iframe.addEventListener('load', fn)
      document.body.appendChild(iframe)

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
