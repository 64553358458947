export default [
  {
    path: '/ballen/1/:GameType/:l1shrper',
    name: 'ballen',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '足球',
      CatID: 1,
    },
  },
  {
    path: '/ballen/3/:GameType/:l1shrper',
    name: 'ballBK',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '籃球',
      CatID: 3,
    },
  },
  {
    path: '/ballen/16/:GameType/:l1shrper',
    name: 'ballKO',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '其他籃球',
      CatID: 16,
    },
  },
  {
    path: '/balltw/4/:GameType/:l1shrper',
    name: 'ballBS',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '美棒',
      CatID: 4,
    },
  },
  {
    path: '/balltw/12/:GameType/:l1shrper',
    name: 'ballBSJ',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '日棒',
      CatID: 12,
    },
  },
  {
    path: '/balltw/14/:GameType/:l1shrper',
    name: 'ballBKO',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '韓棒',
      CatID: 14,
    },
  },
  {
    path: '/balltw/11/:GameType/:l1shrper',
    name: 'ballBST',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '台棒',
      CatID: 11,
    },
  },
  {
    path: '/balltw/13/:GameType/:l1shrper',
    name: 'ballBSO',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '其它棒球',
      CatID: 13,
    },
  },
  {
    path: '/ballen/55/:GameType/:l1shrper',
    name: 'ballTE',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '網球',
      CatID: 55,
    },
  },
  {
    path: '/ballen/82/:GameType/:l1shrper',
    name: 'ballHC',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '冰球',
      CatID: 82,
    },
  },
  {
    path: '/ballen/5/:GameType/:l1shrper',
    name: 'ballUF',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '美足',
      CatID: 5,
    },
  },
  {
    path: '/ballen/83/:GameType/:l1shrper',
    name: 'ballLO',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '彩球',
      CatID: 83,
    },
  },
  {
    path: '/ballen/84/:GameType/:l1shrper',
    name: 'ballID',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '指數',
      CatID: 84,
    },
  },
  {
    path: '/ballen/72/:GameType/:l1shrper',
    name: 'ballOT',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '賽馬賽狗',
      CatID: 72,
    },
  },
  {
    path: '/ballen/85/:GameType/:l1shrper',
    name: 'ballES',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '電競',
      CatID: 85,
    },
  },
  {
    path: '/ballen/21/:GameType/:l1shrper',
    name: 'ballPP',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '乒乓球',
      CatID: 21,
    },
  },
  {
    path: '/ballen/22/:GameType/:l1shrper',
    name: 'ballBM',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '羽毛球',
      CatID: 22,
    },
  },
  {
    path: '/ballen/23/:GameType/:l1shrper',
    name: 'ballVL',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '排球',
      CatID: 23,
    },
  },
  {
    path: '/ballen/24/:GameType/:l1shrper',
    name: 'ballPL',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '撞球',
      CatID: 24,
    },
  },
  {
    path: '/ballen/31/:GameType/:l1shrper',
    name: 'ballWC',
    component: () => import('@/views/pages/trader/ballCommon.vue'),
    meta: {
      title: '冠軍聯賽',
      CatID: 31,
    },
  },
  {
    path: '/ballSuper8/:l1shrper',
    name: 'ballSuper8',
    component: () => import('@/views/pages/trader/ballSuper8.vue'),
    meta: {
      title: '超吉8',
      CatID: 99,
    },
  },
  {
    path: '/ballStop',
    name: 'ballStop',
    component: () => import('@/views/pages/trader/ballStop.vue'),
    meta: {
      title: '停押賽事',
      layout: 'full',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/gameLog',
    name: 'gameLog',
    component: () => import('@/views/pages/trader/gameLog.vue'),
    meta: {
      title: '賠率歷程',
      layout: 'full',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/gameLogDetail',
    name: 'gameLogDetail',
    component: () => import('@/views/pages/trader/gameLogDetail.vue'),
    meta: {
      title: '賠率歷程',
      layout: 'full',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/winLog',
    name: 'winLog',
    component: () => import('@/views/pages/trader/winLog.vue'),
    meta: {
      layout: 'full',
      title: '勝率',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/forecast',
    name: 'forecast',
    component: () => import('@/views/pages/trader/forecast.vue'),
    meta: {
      layout: 'full',
      title: '預測',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/forecastBS',
    name: 'forecastBS',
    component: () => import('@/views/pages/trader/forecastBS.vue'),
    meta: {
      layout: 'full',
      title: '棒球預測',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/forecastLive',
    name: 'forecastLive',
    component: () => import('@/views/pages/trader/forecastLive.vue'),
    meta: {
      layout: 'full',
      title: '預測',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/ballDambes',
    name: 'ballDambes',
    component: () => import('@/views/pages/trader/ballDambes.vue'),
    meta: {
      layout: 'full',
      title: '當貝斯',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/ticketDetailPage',
    name: 'ticketDetailPage',
    component: () => import('@/views/pages/trader/ticketDetailPage.vue'),
    meta: {
      layout: 'full',
      title: '注單',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/ticketDetail',
    name: 'ticketDetail',
    component: () => import('@/views/pages/trader/ticketDetail.vue'),
    meta: {
      layout: 'full',
      title: '注單',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/ticketWaitAll',
    name: 'ticketWaitAll',
    component: () => import('@/views/pages/trader/ticketWaitAll.vue'),
    meta: {
      layout: 'full',
      title: '延遲中注單',
      type: 'open',
      width: 1800,
      height: 750,
    },
  },
  {
    path: '/ticketWaitHis',
    name: 'ticketWaitHis',
    component: () => import('@/views/pages/trader/ticketWaitHis.vue'),
    meta: {
      layout: 'full',
      title: '即時注單',
      type: 'open',
      width: 1300,
      height: 650,
    },
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import('@/views/pages/trader/analysis.vue'),
    meta: {
      title: '分析',
    },
  },
  {
    path: '/memTypeAnalysis',
    name: 'memTypeAnalysis',
    component: () => import('@/views/pages/trader/memTypeAnalysis.vue'),
    meta: {
      title: '辣客分析',
    },
  },
  {
    path: '/winAnalysis',
    name: 'winAnalysis',
    component: () => import('@/views/pages/trader/winAnalysis.vue'),
    meta: {
      title: '勝率分析',
    },
  },
  {
    path: '/performance',
    name: 'performance',
    component: () => import('@/views/pages/trader/performance.vue'),
    meta: {
      title: '成績',
    },
  },
  {
    path: '/ballSCPD/:evtid',
    name: 'ballSCPD',
    component: () => import('@/views/pages/trader/ballSCPD.vue'),
    meta: {
      layout: 'full',
      title: '波膽',
    },
  },
  {
    path: '/ballPD/1/:GameType/:l1shrper',
    name: 'ballPD',
    component: () => import('@/views/pages/trader/ballPD.vue'),
    meta: {
      title: '波膽',
      CatID: 1,
    },
  },
  {
    path: '/ballPD/31/:GameType/:l1shrper',
    name: 'ballWCPD',
    component: () => import('@/views/pages/trader/ballPD.vue'),
    meta: {
      title: '波膽',
      CatID: 31,
    },
  },
  {
    path: '/ballDoubleResult/1/:GameType/:l1shrper',
    name: 'ballDoubleResult',
    component: () => import('@/views/pages/trader/ballDoubleResult.vue'),
    meta: {
      title: '入球數',
      CatID: 1,
    },
  },
  {
    path: '/ballDoubleResult/31/:GameType/:l1shrper',
    name: 'ballWCDoubleResult',
    component: () => import('@/views/pages/trader/ballDoubleResult.vue'),
    meta: {
      title: '入球數',
      CatID: 31,
    },
  },
  {
    path: '/ballGoals/1/:GameType/:l1shrper',
    name: 'ballGoals',
    component: () => import('@/views/pages/trader/ballGoals.vue'),
    meta: {
      title: '半全場',
      CatID: 1,
    },
  },
  {
    path: '/ballGoals/31/:GameType/:l1shrper',
    name: 'ballWCGoals',
    component: () => import('@/views/pages/trader/ballGoals.vue'),
    meta: {
      title: '半全場',
      CatID: 31,
    },
  },
  {
    path: '/ballCH/1/:GameType/:l1shrper',
    name: 'ballSCCH',
    component: () => import('@/views/pages/trader/ballCH.vue'),
    meta: {
      title: '冠軍',
      CatID: 1,
    },
  },
  {
    path: '/ballCH/31/:GameType/:l1shrper',
    name: 'ballSC2CH',
    component: () => import('@/views/pages/trader/ballCH.vue'),
    meta: {
      title: '冠軍',
      CatID: 31,
    },
  },
  {
    path: '/ballCH/3/:GameType/:l1shrper',
    name: 'ballCH',
    component: () => import('@/views/pages/trader/ballCH.vue'),
    meta: {
      title: '冠軍',
      CatID: 3,
    },
  },
  {
    path: '/ballCH/4/:GameType/:l1shrper',
    name: 'ballBSCH',
    component: () => import('@/views/pages/trader/ballCH.vue'),
    meta: {
      title: '冠軍',
      CatID: 4,
    },
  },
  {
    path: '/PassCancel',
    name: 'PassCancel',
    component: () => import('@/views/pages/trader/passCancelTicket.vue'),
    meta: {
      layout: 'full',
      title: '退收單',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
]
