export default [
  {
    path: '/liveScoutSelect',
    name: 'liveScoutSelect',
    component: () => import('@/views/pages/liveScout/liveScoutSelect.vue'),
    meta: {
      layout: 'full',
      title: '選擇燈號賽事',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
  {
    path: '/liveScoutInfo',
    name: 'liveScoutInfo',
    component: () => import('@/views/pages/liveScout/liveScoutInfo.vue'),
    meta: {
      layout: 'full',
      title: '賽事燈號',
      type: 'open',
      width: 1200,
      height: 650,
    },
  },
]
