export default [
  {
    path: '/CrlUser',
    name: 'crlUser',
    component: () => import('@/views/pages/member/crlUser.vue'),
    meta: {
      title: '控盤手',
    },
  },
  {
    path: '/ChangePw',
    name: 'ChangePw',
    component: () => import('@/views/pages/member/ChangePw.vue'),
    meta: {
      title: '修改密碼',
    },
  },
  {
    path: '/Member',
    name: 'mem',
    component: () => import('@/views/pages/member/member.vue'),
    meta: {
      title: '會員管理',
    },
  },
  {
    path: '/MemType',
    name: 'memType',
    component: () => import('@/views/pages/member/memType.vue'),
    meta: {
      title: '辣客設定',
    },
  },
  {
    path: '/DangerType',
    name: 'dangerType',
    component: () => import('@/views/pages/member/dangerType.vue'),
  },
  {
    path: '/MemTypeData',
    name: 'MemTypeData',
    component: () => import('@/views/pages/member/memTypeData.vue'),
    meta: {
      title: '辣客會員',
      layout: 'full',
      type: 'open',
      width: 1380,
      height: 650,
    },
  },
]
