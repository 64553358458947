export default [
  {
    path: '/setting/memTransfer',
    name: 'memTransfer',
    component: () => import('@/views/pages/setting/memTransfer.vue'),
    meta: {
      title: '站點維護',
    },
  },
  {
    path: '/ticket/ticketManage',
    name: 'ticketManage',
    component: () => import('@/views/pages/setting/ticketManage.vue'),
    meta: {
      title: '注單管理',
    },
  },
  {
    path: '/setting/bbs',
    name: 'bbs',
    component: () => import('@/views/pages/setting/BBS.vue'),
    meta: {
      title: '跑馬燈',
    },
  },
  {
    path: '/ticket/query',
    name: 'ticket-query',
    component: () => import('@/views/pages/setting/QueryT.vue'),
    meta: {
      title: '注單查詢',
    },
  },
  {
    path: '/setting/acquNoMatch',
    name: 'acquNoMatch',
    component: () => import('@/views/pages/setting/acquNoMatch.vue'),
    meta: {
      title: '採集無對典',
    },
  },
  {
    path: '/setting/tour',
    name: 'tour',
    component: () => import('@/views/pages/setting/tour.vue'),
    meta: {
      title: '聯賽管理',
    },
  },
  {
    path: '/setting/betSetting',
    name: 'betSetting',
    component: () => import('@/views/pages/setting/betSetting.vue'),
    meta: {
      title: '槍數限制',
    },
  },
  {
    path: '/setting/team',
    name: 'team',
    component: () => import('@/views/pages/setting/team.vue'),
    meta: {
      title: '隊伍管理',
    },
  },
  {
    path: '/setting/teamPitcher',
    name: 'teamPitcher',
    component: () => import('@/views/pages/setting/teamPitcher.vue'),
    meta: {
      title: '投手管理',
    },
  },
  {
    path: '/setting/sysConfig',
    name: 'sysConfig',
    component: () => import('@/views/pages/setting/sysConfig.vue'),
    meta: {
      title: '過關降水設定',
    },
  },
  {
    path: '/setting/defaultOdds',
    name: 'defaultOdds',
    component: () => import('@/views/pages/setting/defaultOdds.vue'),
    meta: {
      title: '賠率預設管理',
    },
  },
  {
    path: '/setting/webM',
    name: 'webM',
    component: () => import('@/views/pages/setting/siteMaintain.vue'),
    meta: {
      title: '維護',
    },
  },
  {
    path: '/api/rptbill/upgUnFTicket',
    name: 'upgUnFTicket',
    meta: {
      link: true,
    },
  },
]
