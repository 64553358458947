export default [

  {
    path: '/ticket/real',
    name: 'ticket-real',
    component: () => import('@/views/pages/Real.vue'),
  },
  {
    path: '/ticket/score',
    name: 'ticket-score',
    component: () => import('@/views/pages/Score.vue'),
  },
  {
    path: '/log',
    name: 'ticket-log',
    component: () => import('@/views/pages/Log.vue'),
    meta: {
      title: '日誌',
      layout: 'full',
      type: 'open',
      width: 1380,
      height: 650,
    },
  },
  
]
