import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    $title: '',
    $otherInfo: {},
    $responseTime: 0,
    $message: {
      mt1: 0, mt2: 0, mt1p: 0, mt2p: 0,
    },
    $messageUser: '',
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      localStorage.setItem('isCollapsed', val ? '1' : '0')
      state.isVerticalMenuCollapsed = val
    },
    updateTitle: (state, val) => {
      state.$title = val.title
      state.$otherInfo = val
    },
    updateTime: (state, val) => {
      state.$responseTime = val
    },
    updateMessage: (state, val) => {
      state.$message = val
    },
    updateMessageUser: (state, val) => {
      state.$messageUser = val
    },
  },
  actions: {},
}
