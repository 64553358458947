// const urlRoot = 'http://localhost:50598'
const urlRoot = '/api'
export default {
  // Endpoints
  maintainQueryEndpoint: `${urlRoot}/game/Query`,
  maintainCOQueryEndpoint: `${urlRoot}/game/COQuery`,
  memCmbEndpoint: `${urlRoot}/game/mem`,
  gameScoreEndpoint: `${urlRoot}/game/gameScore`,
  gameDetailEndpoint: `${urlRoot}/game/gameDetail`,
  returnCheckEndpoint: `${urlRoot}/game/returnCheck`,
  returnCheckEvtEndpoint: `${urlRoot}/game/returnCheckEvt`,
  returnAEndpoint: `${urlRoot}/game/returnA`,
  resumeAEndpoint: `${urlRoot}/game/resumeA`,
  returnGEndpoint: `${urlRoot}/game/returnG`,
  liveEndpoint: `${urlRoot}/game/live`,
  saveliveEndpoint: `${urlRoot}/game/savelive`,
  savehotEvtEndpoint: `${urlRoot}/game/savehotEvt`,
  removeNewGameEndpoint: `${urlRoot}/game/removeNewGame`,
  saveGameEndpoint: `${urlRoot}/game/saveGame`,
  deleteGameEndpoint: `${urlRoot}/game/deleteGame`,
  setCrlUserEndpoint: `${urlRoot}/game/setCrlUser`,
}
