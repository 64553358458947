// const urlRoot = 'http://localhost:50598'
const urlRoot = '/api'
export default {
  // Endpoints
  siteEndpoint: `${urlRoot}/member/sitequery`,
  memberEndpoint: `${urlRoot}/member/query`,
  userDataEndpoint: `${urlRoot}/member/userdata`,
  memberInsertEndpoint: `${urlRoot}/member/insert`,
  memberUpdateEndpoint: `${urlRoot}/member/update`,
  memberUpdateStatusEndpoint: `${urlRoot}/member/updateStatus`,
  memberUpdatePwEndpoint: `${urlRoot}/member/updatePw`,

  dangerTypeQueryEndpoint: `${urlRoot}/member/dangerTypeQuery`,
  dangerTypeInsertEndpoint: `${urlRoot}/member/dangerTypeInsert`,
  dangerTypeUpdateEndpoint: `${urlRoot}/member/dangerTypeUpdate`,

  memTypeQueryEndpoint: `${urlRoot}/member/memTypeQuery`,
  memTypeInsertEndpoint: `${urlRoot}/member/memTypeInsert`,
  memTypeUpdateEndpoint: `${urlRoot}/member/memTypeUpdate`,

  memQueryEndpoint: `${urlRoot}/member/memQuery`,
  memSingleEndpoint: `${urlRoot}/member/memSingle`,
  memUpdateEndpoint: `${urlRoot}/member/memUpdate`,
  
}
