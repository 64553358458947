export default [
  {
    path: '/question/1',
    name: 'user1',
    component: () => import('@/views/pages/question/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      title: '客戶反應',
      mt: 1,
    },
  },
  {
    path: '/question/2',
    name: 'user3',
    component: () => import('@/views/pages/question/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      title: '代理反應',
      mt: 2,
    },
  },
]
