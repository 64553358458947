// const urlRoot = 'http://localhost:50598'
const urlRoot = '/api'
export default {
  // Endpoints
  loginEndpoint: `${urlRoot}/users/authenticate`,
  refreshEndpoint: `${urlRoot}/users/refresh-token`,
  balluscEndpoint: `${urlRoot}/ballen/ucs`,
  balluscSettingEndpoint: `${urlRoot}/ballen/ucsSetting`,
  balluscSettingUpdateEndpoint: `${urlRoot}/ballen/ucsSettingUpdate`,
  balluscEvtEndpoint: `${urlRoot}/ballen/ucsdetail`,
  balluscEvtAdjEndpoint: `${urlRoot}/ballen/ucsoddadj`,
  ballRealEndpoint: `${urlRoot}/ballen/ticketreal`,
  ballQueryEndpoint: `${urlRoot}/ballen/ticketquery`,
  subTicketQueryEndpoint: `${urlRoot}/ballen/subTicketQuery`,
  scoreQueryEndpoint: `${urlRoot}/score/score`,
  logQueryEndpoint: `${urlRoot}/log/log`,
  logDetailEndpoint: `${urlRoot}/log/LogDetail`,
  bbsQueryEndpoint: `${urlRoot}/bbs/bbs`,
  bbsInfoEndpoint: `${urlRoot}/bbs/bbsinfo`,
  bbsTranslateEndpoint: `${urlRoot}/bbs/bbsTranslate`,
  bbsTranslateEndpoint2: 'https://translate.google.com.tw/translate_a/single?client=gtx&dt=t&dj=1&ie=UTF-8&sl=zh-TW&tl=en&q=%E4%BD%A0%E5%A5%BD',
  bbsInsertEndpoint: `${urlRoot}/bbs/bbsinsert`,
  bbsUpdateEndpoint: `${urlRoot}/bbs/bbsupdate`,
  bbsDisplayEndpoint: `${urlRoot}/bbs/bbsdisplay`,
  bbsDeleteEndpoint: `${urlRoot}/bbs/bbsdelete`,

  checkLoginEndpoint: `${urlRoot}/users/isLogin`,
  checkLogin2Endpoint: `${urlRoot}/users/checkLogin`,
  loginoutEndpoint: `${urlRoot}/users/logout`,
  favouriteEndpoint: `${urlRoot}/favourite/favourite`,
  addFavouriteEndpoint: `${urlRoot}/favourite/addFavourite`,
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
